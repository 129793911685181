<template>
  <div id="app-team-order-rearrange">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              large
              @click.prevent="backPage"
              color="primary"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark> mdi-arrow-left-circle </v-icon> &nbsp; Back
            </v-btn>
            <v-btn
              large
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Move</th>
                    <th scope="col">Order</th>
                    <th scope="col">Name</th>
                    <th scope="col">Old Order</th>
                  </tr>
                </thead>
                <draggable v-model="tableData1" tag="tbody" handle=".handle">
                  <tr v-for="(item, index) in tableData1" :key="item.TeamId">
                    <td><i class="fa fa-align-justify handle"></i></td>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.TeamName }}</td>
                    <td>{{ item.TeamOrder }}</td>
                  </tr>
                </draggable>
              </table>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                color="success"
                class="mr-4"
                :loading="SubmitFlag"
                @click.prevent="submitForm"
              >Submit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  mixins: [common],
  components: { draggable },
  data() {
    return {
      LoadingFlag: false,
      SearchFlag: false,
      OverlayLoadingFlag: false,
      SubmitFlag: false,
      uploadPercentage: 0,

      Teams: [],

      JciYearCode: "",

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getTableRecords();
      }
    }
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "wm_team",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/wm/team/lists";

      var upload = {
        UserInterface: 1
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          console.log({ records });

          var TableContent = response.data.table_content;

          var options = TableContent.TableOptions;
          console.log({ options });
          thisIns.tableOptions1 = options;

          if (flag == 1) {
            thisIns.tableColumns1 = TableContent.TableHeader;
            thisIns.tableData1 = records;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    submitForm() {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");

      var Teams = this.tableData1;
      console.log("Teams=" + JSON.stringify(Teams));

      if (Teams.length > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/wm/team/rearrange";
        var upload = {
          UserInterface: 1,
          Teams: Teams
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;
        this.OverlayLoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.backPage();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.OverlayLoadingFlag = false;
            thisIns.sweetAlert("error", error, false);
          });
      } else {
        var message = "";
        if (Teams.length <= 0) {
          message += "No Records Found. kindly check and update.";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#app-team-order-rearrange {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>